import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { MapQuery } from '../../../graphql-types';
import Helmet from 'react-helmet';

interface MapProps {
  height?: React.CSSProperties['height'];
  googlePlaceId?: string;
}

export const Map = ({ height = '320px', googlePlaceId }: MapProps) => {
  const data = useStaticQuery<MapQuery>(graphql`
    query Map {
      site {
        siteMetadata {
          googleMapsApiKey
          organization {
            googlePlaceId
          }
        }
      }
    }
  `);
  const apiKey = data.site?.siteMetadata?.googleMapsApiKey;
  const placeId =
    googlePlaceId || data.site?.siteMetadata?.organization?.googlePlaceId;

  // If we have no API key and we are built for production, don't render.
  if ((process.env.NODE_ENV === 'production' && !apiKey) || !placeId) {
    return null;
  }

  return (
    <div style={{ height, backgroundColor: 'var(--palette-background)' }}>
      <Helmet>
        {/* <link rel="preconnect" href="https://maps.gstatic.com" /> */}
        <link rel="preconnect" href="https://maps.googleapis.com" />
        <link rel="preconnect" href="https://www.google.com" />
      </Helmet>
      <iframe
        title="Map of location"
        width="100%"
        height={height}
        frameBorder="0"
        style={{ border: '0' }}
        src={`https://www.google.com/maps/embed/v1/place?q=place_id:${placeId}&key=${apiKey}`}
      />
    </div>
  );
};
