import * as React from 'react';

import FiveStarsIcon from './five-stars.inline.svg';

import styles from './five-star-words.module.css';

interface FiveStarWordsProps {
  children: React.ReactNode;
  className?: string;
}

export const FiveStarWords = ({ children, className }: FiveStarWordsProps) => {
  return (
    <div className={[styles.container, className].filter((c) => c).join(' ')}>
      <div className={styles.words}>"{children}"</div>
      <div className={styles.icon}>
        <FiveStarsIcon />
      </div>
    </div>
  );
};
