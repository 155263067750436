import * as React from 'react';
import { graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

import { HomeQuery } from '../../graphql-types';
import { Layout } from '../components/Layout';
import { Container } from '../components/Container';
import SEO from '../components/seo';
import { Section } from '../components/Section';
import { Map } from '../components/Map';
import { Address } from '../components/Address';
import { Reviews } from '../components/Reviews';
import { GoToLink } from '../components/GoToLink';

import CertificateIcon from '../images/home/certificate.inline.svg';
import CreditCardIcon from '../images/home/credit-card.inline.svg';
import FriendsIcon from '../images/home/friends.inline.svg';
import HearingAidIcon from '../images/home/hearing-aid.inline.svg';
import HomeIcon from '../images/home/home.inline.svg';
import CTAArrowIcon from '../images/home/cta-arrow.inline.svg';
import { FiveStarWords } from '../components/FiveStarWords';
import { CallMe } from '../components/CallMe';

import styles from './index.module.css';

interface IndexPageProps {
  data: HomeQuery;
}

const IndexPage = ({ data }: IndexPageProps) => {
  const homeHeroFluid = data.homeHero?.childImageSharp?.fluid as FluidObject;
  const practionerFluid = data.practioner?.childImageSharp
    ?.fluid as FluidObject;
  const insuranceFluid = data.insurance?.childImageSharp?.fluid as FluidObject;
  const org = data.site?.siteMetadata?.organization;

  return (
    <Layout>
      <SEO title="Home" />

      <Section>
        <Container>
          <p>Family Business. Locally owned.</p>
          <h1>
            Try new hearing aids at home for 30 days.
            <br />
            Risk-free.
          </h1>

          <CallMe>
            <div className={styles.markerText}>
              <div>Everyone has questions, don't be shy.</div>
              <CTAArrowIcon />
            </div>
          </CallMe>

          <div className={styles.fiveStarWords}>
            <FiveStarWords className={styles.fiveStarWord}>
              I'd recommend Don anytime.
            </FiveStarWords>

            <FiveStarWords className={styles.fiveStarWord}>
              Excellent follow-up care.
            </FiveStarWords>

            <FiveStarWords className={styles.fiveStarWord}>
              Service you don't find anymore.
            </FiveStarWords>

            <FiveStarWords className={styles.fiveStarWord}>
              Changed my life dramatically.
            </FiveStarWords>

            <div className={styles.fiveStarWordsSubline}>
              50+ five-star Google reviews.
            </div>
          </div>

          <Img
            fluid={homeHeroFluid}
            alt="Our neighborhood office"
            style={{ marginBottom: '0', borderRadius: 8 }}
            loading="lazy"
          />

          <ul className={styles.takeaways}>
            <li className={styles.takeaway}>
              <div className={styles.takeawayIcon}>
                <HomeIcon />
              </div>
              <div className={styles.takeawayLabel}>
                30-day <br />
                home try-on.
              </div>
            </li>

            <li className={styles.takeaway}>
              <div className={styles.takeawayIcon}>
                <CreditCardIcon />
              </div>
              <div className={styles.takeawayLabel}>
                Simple <br />
                0% financing.
              </div>
            </li>

            <li className={styles.takeaway}>
              <div className={styles.takeawayIcon}>
                <FriendsIcon />
              </div>
              <div className={styles.takeawayLabel}>
                Hassle-free <br />
                returns.
              </div>
            </li>

            <li className={styles.takeaway}>
              <div className={styles.takeawayIcon}>
                <HearingAidIcon />
              </div>
              <div className={styles.takeawayLabel}>
                Discreet <br />
                hearing aids.
              </div>
            </li>

            <li className={styles.takeaway}>
              <div className={styles.takeawayIcon}>
                <CertificateIcon />
              </div>
              <div className={styles.takeawayLabel}>
                3yr wty on <br />
                nearly all devices.
              </div>
            </li>
          </ul>
        </Container>
      </Section>

      <Section altBackground>
        <Container>
          <div className={styles.appointment}>
            <div className={styles.appointmentText}>
              <h2>Your first appointment with Don is a breeze.</h2>
              <ol>
                <li>Come in for a series of complimentary hearing tests.</li>
                <li>Pick the hearing aids you want to try-out at home.</li>
                <li>
                  We custom program the hearing aids to your specific loss.
                </li>
                <li>Not worth the money? Hassle-free returns.</li>
              </ol>
            </div>
            <div className={styles.appointmentImage}>
              <Img
                fluid={practionerFluid}
                alt="Portrait of Don"
                loading="lazy"
              />
            </div>
            <div className={styles.appointmentLink}>
              <GoToLink to="/meet-don">Meet Don</GoToLink>
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <h2>Turns out people love Don's no pressure approach...</h2>

          <Reviews
            limit={5}
            ids={[
              '6954a562-124f-563a-8b5a-fdd684c955ee',
              '8de7c0bc-72ef-5b60-b8fc-2db0a1ddea2f',
              '1e2b9e7f-5460-5272-a417-a8e050074fa2',
              '9c050bdf-1666-5a13-9017-2aa3becdca52',
              'e38c8455-6447-535e-9e58-01d2ede8ff73',
            ]}
          />

          <GoToLink to="/reviews">See more reviews</GoToLink>
        </Container>
      </Section>

      <Section altBackground>
        <Container>
          <div className={styles.insurance}>
            <div className={styles.insuranceText}>
              <h2>Not sure how much your insurance covers? We’ll find out.</h2>
              <p>
                After you make an appointment we immediately start researching
                your benefits. By the time you come in for your evaluation,
                we’ll know exactly how much your insurance covers.
              </p>
            </div>
            <figure className={styles.insuranceImage}>
              <Img
                fluid={insuranceFluid}
                alt="Gabby, Medical Billing Specialist"
                loading="lazy"
              />
              <figcaption className={styles.insuranceFigCaption}>
                Gabby, Medical Billing Specialist
              </figcaption>
            </figure>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <h2>Find Don in Powell or Cody.</h2>

          <div className={styles.directions}>
            <div className={styles.directionsLocation}>
              <p>
                In the Coulter Mall, at the corner of Coulter Ave and Division
                St (right behind Domino's)
              </p>
              <div className={styles.mapContainer}>
                <Map
                  height={400}
                  googlePlaceId={
                    data.allContentfulLocation.edges[0].node
                      .googlePlaceId as string
                  }
                />
              </div>
              {org?.address ? (
                <Address
                  recipient={
                    data.allContentfulLocation.edges[0].node.name as string
                  }
                  address={
                    data.allContentfulLocation.edges[0].node
                      .address as React.ComponentProps<
                      typeof Address
                    >['address']
                  }
                />
              ) : null}
            </div>

            <div className={styles.directionsLocation}>
              <p>
                In the Alpine Medical Building adjacent to the Big Horn Medical
                Center
              </p>
              <div className={styles.mapContainer}>
                <Map
                  height={400}
                  googlePlaceId={
                    data.allContentfulLocation.edges[1].node
                      .googlePlaceId as string
                  }
                />
              </div>
              {org?.address ? (
                <Address
                  recipient={
                    data.allContentfulLocation.edges[1].node.name as string
                  }
                  address={
                    data.allContentfulLocation.edges[1].node
                      .address as React.ComponentProps<
                      typeof Address
                    >['address']
                  }
                />
              ) : null}
            </div>
          </div>
          <GoToLink to="/locations">See our locations</GoToLink>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query Home {
    homeHero: file(relativePath: { eq: "home-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1076, maxHeight: 350, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    practioner: file(relativePath: { eq: "practitioner.png" }) {
      childImageSharp {
        fluid(maxWidth: 320, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    insurance: file(relativePath: { eq: "insurance-team.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320, maxHeight: 320, cropFocus: ATTENTION) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    site {
      siteMetadata {
        organization {
          name
          phoneNumber
          email
          address {
            city
            secondaryAddress
            state
            streetAddress
            zipcode
          }
        }
      }
    }

    allContentfulLocation(limit: 2, sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          id
          address {
            streetAddress
            state
            zipcode
            secondaryAddress
            city
          }
          googlePlaceId
          name
        }
      }
    }
  }
`;

export default IndexPage;
